import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";

const keyList = {
    smsUpdate : 'LOCATION_UPDATE',
    smsDelete : 'LOCATION_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,

    state : {

        location:[],
        locationLoading: false,
        locationError: false,
        locationsubmitSuccess:false,


        discussions:[],
        discussionLoading: false,
        discussionError: false,

        ...store
    },
    
    mutations : {
        ...mutations,


        SET_LOCATION(state, location){
            state.location = location;
        
        },

        LOCATION_LOADING (state, loading) {
            state.locationLoading = loading;
        },

        LOCATION_ERROR (state, loading) {
            state.locationError = loading;
        },

        LOCATION_SUBMIT_SUCCESS (state, success) {
            state.locationsubmitSuccess = success;
        },


        SET_DISCUSSION(state, discussion){
            state.discussions = discussion;
        
        },

        DISCUSSION_LOADING (state, loading) {
            state.discussionLoading = loading;
        },

        DISCUSSION_ERROR (state, loading) {
            state.discussionError = loading;
        },
    },

    actions: {
        //Location
        async addLocation({commit, dispatch}, payload) {
            commit('LOCATION_LOADING', true);
            commit('LOCATION_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.create_location, payload, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('LOCATION_SUBMIT_SUCCESS', true);
                dispatch ('fetchLocation')
                return response

            }catch (e) {
                commit('LOCATION_ERROR', true);
            }finally {
                commit('LOCATION_LOADING', false);
            }
        },

        async fetchLocation ({ commit },payload) {
            commit('LOCATION_LOADING', true);
            commit('LOCATION_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_location, config);
                const results = result.data.data
                commit('SET_LOCATION',results);

            } catch(error) {
                commit('LOCATION_ERROR', true);
            }
            commit('LOCATION_LOADING', false);
        },

        


        async deleteLocation ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_location + payload, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch('fetchLocation');
            return response
        },



        //Discussion

        async addDiscussion({commit, dispatch}, payload) {
            commit('DISCUSSION_LOADING', true);
            commit('DISCUSSION_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.create_discussion, payload.discussion, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('LOCATION_SUBMIT_SUCCESS', true);
                dispatch ('fetchDiscussion',{location:payload.location})
                return response

            }catch (e) {
                commit('DISCUSSION_ERROR', true);
            }finally {
                commit('DISCUSSION_LOADING', false);
            }
        },

        async fetchDiscussion ({ commit },payload) {
            commit('DISCUSSION_LOADING', true);
            commit('DISCUSSION_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_discussion_by_location + payload.location, config);
                const results = result.data.data
                commit('SET_DISCUSSION',results);

            } catch(error) {
                commit('DISCUSSION_ERROR', true);
            }
            commit('DISCUSSION_LOADING', false);
        },

        


        async deleteDiscussions ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_discussion + payload.delete, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch('fetchDiscussion',{location:payload.location});
            return response
        },







    },

    getters: {}
}
