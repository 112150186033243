<template>
<div>
    <Breadcrumbs main="Mission Rescue Bangladesh" title="Location" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">

                    <div class="card-header">
                        <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                            <i class="fa fa-light fa-plus"></i>
                            <h7>Add New Location</h7>
                        </button>
                    </div>

                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">

                                <b-input-group class="datatable-btn">
                                    <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>

                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <div v-if="!loading && !error">
                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="location_list" :fields="tablefields" :current-page="currentPage" :filter="filter" :per-page="perPage" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                            <feather v-if="data.item.name !== 'All'" type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteLocation(data.item.id)" title="Delete"></feather>
                                            <feather type="message-circle" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-scrollable @click="Reply(data.item)" title="Reply"></feather>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
                        </div>
                        <div v-else>
                            <Loader />
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Location Create Modal -->
    <b-modal id="modal-prevent-closing" ref="modal" title="Add Location" @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group label="Name" label-for="name-input">
                <b-form-input v-model="location.name" placeholder="Enter location name"></b-form-input>
            </b-form-group>

        </form>
    </b-modal>

    <!-- Delete Modal -->
    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this location?</div>
    </b-modal>

    <!-- Reply Create Modal -->
    <b-modal id="modal-scrollable" ref="modal" title="Discussions" hide-footer @ok="handleReplyOk"> 

        <div v-if="discussion_reply.length>0">
            <h6>Replies</h6>
            <div class="task-board-comments">
                <div v-for="reply in discussion_reply" :key="reply.id" class="chatbox">
                    <div class="chat">
                       
                        <div class="message">
                            <h7 class="name pl-1">{{ reply.name }}</h7>
                            <p class="msg" v-html="convertUrls(reply.message)"></p>
                        </div>
                        <div>
                              <feather  type="delete" size="16px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-discussion-delete  title="Delete" @click="deleteRely(reply.id)"></feather>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <p>No discussions found</p>
        </div>

        <div class="task-board-comment-box" style="margin-top: 10px;">
            <div style="display: flex; gap: 12px;">
                <input class="form-control" type="text" v-model="my_reply.message" placeholder="Write your reply here..." @keyup.enter="handleReplySubmit()">
                <button @click="handleReplySubmit()" class="btn btn-primary">Send</button>
            </div>
        </div>

    </b-modal>


        <!-- Delete Modal -->
    <b-modal id="modal-discussion-delete" centered hide-header @ok="handleReplyDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this reply?</div>
    </b-modal>

</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Loader from '../loader/loader.vue'

export default {
    components: {
        Loader,
    },
    data() {
        return {
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'name',
                    label: 'Title',
                    sortable: true
                },

                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],

            statusOptions: [{
                    text: 'Active',
                    value: true
                },
                {
                    text: 'Inactive',
                    value: false
                }
            ],

            location: {
                name: '',

            },

            edit_location: {
                id: '',
                name: '',

            },

            my_reply:{
                message:'',
                location:'',
            },
            location_slug:null,

            delete_value: null,
            reply_id:null,
            udate_value: null,

            filter: null,

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15],

            temp_quotation: {}
        };
    },

    computed: {
        ...mapState({
            location_list: state => state.location.location,
            loading: state => state.location.locationLoading,
            error: state => state.location.locationError,
            discussion_reply: state => state.location.discussions,
            dicussionloading: state => state.location.discussionLoading,
            discussionerror: state => state.location.discussionError,
        }),
        sortOptions() {
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        }
    },

    mounted() {

        this.$store.dispatch("location/fetchLocation", {}).then(() => {
            this.items = this.location_list;
            this.totalRows = this.items.length;

        });

    },
    watch: {

    },

    methods: {

        resetReplyModal(){
            this.my_reply.message = null
            this.my_reply.location = null
        },
        Reply(type) { 
            this.location_slug = type.slug
            this.my_reply.location = type.id
            this.$store.dispatch("location/fetchDiscussion",{location:type.slug})
        },

        convertUrls(text) {
            if (text === null) {
                return '';
            }
            const urlPattern = /(https?:\/\/[^\s<]+)/g;
            return text.replace(urlPattern, (url) => {
                return `<a href="${url}" target="_blank" class="text-primary">${url}</a>`;
            });
        },

        onFiltered(filteredItems) {

            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        resetModal() {
            this.location.name = null
        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },

        handleSubmit() {
            this.$store.dispatch("location/addLocation", this.location).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.location.name = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        },


        editLocation(type) {
            this.edit_location.id = type.id
            this.edit_location.name = type.name

        },

        resetEditModal() {
            this.edit_location.id = null
            this.edit_location.name = null
        },

        handleEditOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleEditSubmit()
        },

        handleEditSubmit() {
            this.$store.dispatch("location/updateLocation", {
                id: this.edit_location.id,
                location: this.edit_location
            }).then(response => {

                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.edit_location.id = null
                    this.edit_location.name = null
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-edit')
            })
        },
        deleteLocation(id) {
            this.delete_value = id
        },
        handleDeleteOk() {
            this.$store.dispatch("location/deleteLocation", this.delete_value).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },



        
        handleReplyOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleReplySubmit()
        },

        handleReplySubmit() {
            this.$store.dispatch("location/addDiscussion", {
               
                discussion: this.my_reply,
                location: this.location_slug,
                
            }).then(response => {

                if (response.code == 201) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                       
                    this.my_reply.message = null
                      

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

           
        },

        deleteRely(id) {
            this.reply_id = id
        },
        handleReplyDeleteOk() {
            this.$store.dispatch("location/deleteDiscussions", {delete:this.reply_id,
            location: this.location_slug,
                
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },

    }
};
</script>

<style scoped>
.badge-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    background-color: #2c323f;
    padding: 0.375rem 1.75rem;
    color: white;
}

.badge-style:hover {
    background-color: #ff595d;
}

.chatbox {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0px 10px;
}

.chat {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    word-break: break-all;
}

.avatar img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: solid 2px white;
}

.message {
    flex-grow: 1;
}

.msg {
    background: #fff0f0;
    width: 100%;
    /* Ensures message takes full width */
    padding: 10px;
    border-radius: 10px;
    position: relative;
    font-size: 13px;
    word-break: break-all;
}

.task-board-comments {
    height: 450px;
    overflow-y: auto;
}

/* height */
::-webkit-scrollbar {
    height: 6px;
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>
