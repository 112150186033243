import Vue from "vue";
import Router from "vue-router";
import firebase from "firebase";
import Userauth from "../auth/js/index";

import Body from "../components/body";
import Auth from "../auth/auth";

/* Auth */
import login from "../auth/login";
import Register from "../auth/register";
import resetPassword from '../auth/reset-password.vue'

// import Register from "../pages/authentication/register_image2";

/* maintenance */
import Maintenance from "../pages/maintenance";

/* Error Pages */
import Error400 from "../errors/error_400";
import Error401 from "../errors/error_401";
import Error403 from "../errors/error_403";
import Error404 from "../errors/error_404";
import Error500 from "../errors/error_500";
import Error503 from "../errors/error_503";

/* comingsoon */
import ComingsoonSimple from "../pages/comingsoon/comingsoon_simple";
import ComingsoonImage from "../pages/comingsoon/comingsoon_image";
import ComingsoonVideo from "../pages/comingsoon/comingsoon_video";

/* Authentication */
import LoginOne from "../pages/authentication/login_one";
import LoginTwo from "../pages/authentication/login_two";
import LoginValidate from "../pages/authentication/login_with_validation";
import RegisterImage from "../pages/authentication/register_image";
import RegisterImage2 from "../pages/authentication/register_image2";
import Unlock from "../pages/authentication/unlock";
import ForgetPassword from "../pages/authentication/forget_password";
import ResetPassword from "../pages/authentication/reset_password";

//Notifier
import Dashboard from "../notifier/dashboard/default"
import SendSms from "../notifier/smsManagement/sendSms"
import DynamicSms from "../notifier/smsManagement/dynamicSms"
import SmsTemplate from "../notifier/smsTemplate/smsTemplate"
import PriceAndCoverage from "../notifier/priceAndCoverage/priceAndCoverage"
import ContactGroup from "../notifier/addressBook/contactGroup"
import ManageContact from "../notifier/addressBook/manageContact"
import Billing from "../notifier/billing/billing"
import TodaysReport from "../notifier/deliveryReport/todaysDeliveryReport"
import AllReport from "../notifier/deliveryReport/allTimeDeliveryReport"
import ChangePassword from "../notifier/user/changePassword"
import ApiDocumentation from "../notifier/documentation/apiDocumentation"





// Article 19

// Site Settings
import siteSetting from '../pages/article19/site_setting/site_setting.vue'

//Campaign

import campaign from '../pages/article19/campaign/campaign.vue'
import campaignCreate from '../pages/article19/campaign/campaign_create.vue'
import campaignCategory from '../pages/article19/campaign/campaign_category.vue'
import campaignEdit from '../pages/article19/campaign/campaign_edit.vue'
import customCampaign from '../pages/article19/campaign/custom_campaign.vue'

//Law
import law from '../pages/article19/law&policy/law.vue'
import lawCreate from '../pages/article19/law&policy/law_create.vue'
import lawCategory from '../pages/article19/law&policy/law_category.vue'
import lawEdit from '../pages/article19/law&policy/law_edit.vue'
import customLaw from '../pages/article19/law&policy/custom_law.vue'

//Press Release
import press from '../pages/article19/press_release/press.vue'
import pressCreate from '../pages/article19/press_release/press_create.vue'
import pressEdit from '../pages/article19/press_release/press_edit.vue'

//Quotes
import quotes from '../pages/article19/press_release/quotes/quotes.vue'
import quotesCreate from '../pages/article19/press_release/quotes/quotes_create.vue'
import quotesEdit from '../pages/article19/press_release/quotes/quotes_edit.vue'

//News
import news from '../pages/article19/news/news.vue'
import newsCreate from '../pages/article19/news/news_create.vue'
import newsCategory from '../pages/article19/news/news_category.vue'
import newsEdit from '../pages/article19/news/news_edit.vue'
import customNews from '../pages/article19/news/custom_news.vue'

//Team Member
import team from '../pages/article19/team/team.vue'
import teamCreate from '../pages/article19/team/team_create.vue'
import teamEdit from '../pages/article19/team/team_edit.vue'


//Gallery
import gallery from '../pages/article19/gallery/gallery.vue'


//Content
import content from '../pages/article19/content/content.vue'

//Slider
import slider from '../pages/article19/slider/slider.vue'

//Partners
import partner from '../pages/article19/partner/partner.vue'

//Contact
import contact from '../pages/article19/contact/contact.vue'

//Base Details
import baseDetail from '../pages/article19/base_detail/base_detail.vue'


//QnA
import QnA from '../pages/article19/qna/qna.vue'

//Subscribers
import subscribers from '../pages/article19/subscribers/subscribers.vue'



//Annual Reports
import annualReport from '../pages/article19/annual_report/annual_report.vue'

//Counter
import counter from '../pages/article19/counter/counter.vue'


//What we do
import what_we_do from '../pages/article19/what_we_do/what_we_do.vue'
import what_we_do_create from '../pages/article19/what_we_do/what_we_do_create.vue'
import customWhatWeDo from '../pages/article19/what_we_do/custom_what_we_do.vue'

//Application
import Application from "../pages/article19/application_form/application.vue";
import ApplicationCreate from "../pages/article19/application_form/application-create.vue";
import ApplicationEdit from "../pages/article19/application_form/application-edit.vue";
import AddApplicationForm from "../pages/article19/application_form/add-form.vue";
import EditApplicationForm from "../pages/article19/application_form/edit-form.vue";
import ViewApplicationForm from "../pages/article19/application_form/view-form.vue";
import ApplicationResponses from "../pages/article19/application_form/responses.vue";






//Mission_Rescue_Bangladesh
//Location
import location from "../pages/mrb/location/location.vue";

//Contact
import Contact from "../pages/mrb/contact/contact.vue";
import EmergencyContact from "../pages/mrb/contact/emergency_contact.vue";
//Donation
import Donation from "../pages/mrb/donation/donation.vue";

import {store} from "@/store";

Vue.use(Router);

const routes = [
    //Article 19
    // Admin
    {
        path: "/admin",
        component: Body,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: "site-setting",
                name: "siteSetting",
                component: siteSetting,
                meta: {
                    title: "Site Setting",
                }
            },
            // Campaign
            {
                path: "campaign",
                name: "campaign",
                component: campaign,
                meta: {
                    title: "Campaign",
                }
            },
            {
                path: "campaign-create",
                name: "campaignCreate",
                component: campaignCreate,
                meta: {
                    title: "Campaign Create",
                }
            },
            {
                path: "campaign-edit/:slug",
                name: "campaignEdit",
                component: campaignEdit,
                meta: {
                    title: "Campaign Edit",
                }
            },
            {
                path: "campaign-category",
                name: "campaignCategory",
                component: campaignCategory,
                meta: {
                    title: "Campaign Category",
                }
            },
            {
                path: "custom-campaign/:slug",
                name: "customCampaign",
                component: customCampaign,
                meta: {
                    title: "Custom Campaign",
                }
            },

            

            // Law
            {
                path: "law-policy",
                name: "law",
                component: law,
                meta: {
                    title: "Law",
                }
            },
            {
                path: "law-policy-create",
                name: "lawCreate",
                component: lawCreate,
                meta: {
                    title: "Law & Policy Create",
                }
            },
            {
                path: "law-policy-edit/:slug",
                name: "lawEdit",
                component: lawEdit,
                meta: {
                    title: "Law & Policy Edit",
                }
            },
            {
                path: "law-policy-category",
                name: "lawCategory",
                component: lawCategory,
                meta: {
                    title: "Law & Policy Category",
                }
            },
            {
                path: "custom-law-policy/:slug",
                name: "customLaw",
                component: customLaw,
                meta: {
                    title: "Custom Law and Policy",
                }
            },

            // Press Release
            {
                path: "press-release",
                name: "press",
                component: press,
                meta: {
                    title: "Press Release",
                }
            },
            {
                path: "press-release-create",
                name: "pressCreate",
                component: pressCreate,
                meta: {
                    title: "Press Release Create",
                }
            },
            {
                path: "press-release-edit/:slug",
                name: "pressEdit",
                component: pressEdit,
                meta: {
                    title: "Press Release Edit",
                }
            },

            //Quotes
            {
                path: "quotes",
                name: "quotes",
                component: quotes,
                meta: {
                    title: "Quotes",
                }
            },
            {
                path: "quotes-create",
                name: "quotesCreate",
                component: quotesCreate,
                meta: {
                    title: "Quotes Create",
                }
            },
            {
                path: "quotes-edit/:slug",
                name: "quotesEdit",
                component: quotesEdit,
                meta: {
                    title: "Quotes Edit",
                }
            },

            //What we do
            {
                path: "what-we-do",
                name: "what-we-do",
                component: what_we_do,
                meta: {
                    title: "What We Do",
                }
            },
            {
                path: "what-we-do/:type",
                name: "what-we-do-create",
                component: what_we_do_create,
                meta: {
                    title: "Details",
                }
            },
            {
                path: "custom-what-we-do/:type",
                name: "customWhatWeDo",
                component: customWhatWeDo,
                meta: {
                    title: "Custom What We Do",
                }
            },

            //News

            {
                path: "news",
                name: "news",
                component: news,
                meta: {
                    title: "News",
                }
            },
            {
                path: "news-create",
                name: "newsCreate",
                component: newsCreate,
                meta: {
                    title: "News Create",
                }
            },
            {
                path: "news-edit/:slug",
                name: "newsEdit",
                component: newsEdit,
                meta: {
                    title: "News Edit",
                }
            },
            {
                path: "news-category",
                name: "newsCategory",
                component: newsCategory,
                meta: {
                    title: "News Category",
                }
            },

            {
                path: "custom-news/:slug",
                name: "customNews",
                component: customNews,
                meta: {
                    title: "Custom News",
                }
            },

            //Gallery
            {
                path: "gallery",
                name: "gallery",
                component: gallery,
                meta: {
                    title: "Gallery",
                }
            },

             //Content
             {
                path: "content",
                name: "content",
                component: content,
                meta: {
                    title: "Content",
                }
            },


            //Slider
             {
                path: "slider",
                name: "slider",
                component: slider,
                meta: {
                    title: "Slider",
                }
            },

            //Partners
            {
                path: "partner",
                name: "partner",
                component: partner,
                meta: {
                    title: "Partners",
                }
            },

            //Annual Reports
            {
                path: "annual-reports",
                name: "annualReport",
                component: annualReport,
                meta: {
                    title: "Annual Reports",
                }
            },

             //Counter
             {
                path: "counter",
                name: "counter",
                component: counter,
                meta: {
                    title: "Counter",
                }
            },

             //QnA
             {
                path: "qna",
                name: "QnA",
                component: QnA,
                meta: {
                    title: "QnA",
                }
            },

            //Subscribers
              {
                path: "subscribers",
                name: "subscribers",
                component: subscribers,
                meta: {
                    title: "Subscribers",
                }
            },

            //Application Form

            {
                path: "application",
                name: "application",
                component: Application,
                meta: {
                    title: "Application",
                },
            },
            {
                path: "application/create",
                name: "ApplicationCreate",
                component: ApplicationCreate,
                meta: {
                    title: "Application Create",
                },
            },
            {
                path: "application/edit/:id",
                name: "ApplicationEdit",
                component: ApplicationEdit,
                meta: {
                    title: "Application Edit",
                },
            },
            {
                path: "application/responses/:id",
                name: "ApplicationResponses",
                component: ApplicationResponses,
                meta: {
                    title: "Application Responses",
                },
            },
            {
                path: "application/add-form/:id",
                name: "AddApplicationForm",
                component: AddApplicationForm,
                meta: {
                    title: "Application Form",
                },
            },

            {
                path: "application/edit-form/:id",
                name: "EditApplicationForm",
                component:EditApplicationForm,
                meta: {
                    title: "Application Form",
                },
            },


            {
                path: "application/view-form/:id",
                name: "ViewApplicationForm",
                component: ViewApplicationForm,
                meta: {
                    title: "View Application Form",
                },
            },




            //Contact
            {
                path: "contact",
                name: "contact",
                component: contact,
                meta: {
                    title: "Messages",
                }
            },

            // Team Member
            {
                path: "team",
                name: "team",
                component: team,
                meta: {
                    title: "Team Member",
                }
            },
            {
                path: "team-create",
                name: "teamCreate",
                component: teamCreate,
                meta: {
                    title: "Team Member Create",
                }
            },
            {
                path: "team-edit/:slug",
                name: "teamEdit",
                component: teamEdit,
                meta: {
                    title: "Team Member Edit",
                }
            },

            //Base Detail
            {
                path: "base-detail/:slug",
                name: "baseDetail",
                component: baseDetail,
                meta: {
                    title: "Details",
                },
            },
            
        ]
    },



    //MRB
    {
        path: "/mission-rescue-bangladesh",
        component: Body,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: "location",
                name: "location",
                component: location,
                meta: {
                    title: "Location",
                },
            },

            {
                path: "contact",
                name: "contact",
                component: Contact,
                meta: {
                    title: "Contact List",
                },
            },
            
            {
                path: "emergency-contact",
                name: "emergencyContact",
                component: EmergencyContact,
                meta: {
                    title: "Emergency Contacts",
                },
            },

            
            {
                path: "donation/:slug",
                name: "donation",
                component: Donation,
                meta: {
                    title: "Donation List",
                },
            },
        ]
        },








    //Notifier
    {
        path: "/auth",
        component: Auth,
        children: [
            {
                path: "login",
                name: "Login 1",
                component: login,
                meta: {
                    title: " login",
                },
            },
           
            
            {
                path: "register",
                name: "register 1",
                component: Register,
                meta: {
                    title: " Register | Ambala",
                }
            },
            {
                path: "reset-password",
                name: "Reset Password",
                component: resetPassword,
                meta: {
                    title: " Reset Password"
                }
            }
        ]
    },

    { path: "", redirect: { name: "location" } },

    {
        path: "/dashboard",
        component: Body,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: "",
                name: "dashboard",
                component: Dashboard,
                meta: {
                    title: "Dashboard"
                }
            }
        ]
    },



    //Notifier -end


    // {
    //     path: "/dashboard",
    //     component: Body,
    //     meta: {
    //         requiresAuth: true,
    //     },
    //     children: [
    //         {
    //             path: "default",
    //             name: "default",
    //             component: Default,
    //             meta: {
    //                 title: "Default Dashboard | Cuba - Premium Admin Template",
    //             },
    //         },
    //         {
    //             path: "ecommerce",
    //             name: "ecommerce",
    //             component: Ecommerce,
    //             meta: {
    //                 title: "Ecommerce Dashboard | Cuba - Premium Admin Template",
    //             },
    //         },
    //     ],
    // },

    {
        path: "/error-400",
        name: "Error400",
        component: Error400,
        meta: {
            title: "Error400 | Cuba - Premium Admin Template",
        },
    },
    {
        path: "/error-401",
        name: "Error401",
        component: Error401,
        meta: {
            title: "Error401 | Cuba - Premium Admin Template",
        },
    },
    {
        path: "/error-403",
        name: "Error403",
        component: Error403,
        meta: {
            title: "Error403 | Cuba - Premium Admin Template",
        },
    },
    {
        path: "/error-404",
        name: "Error404",
        component: Error404,
        meta: {
            title: "Error404 | Cuba - Premium Admin Template",
        },
    },
    {
        path: "/error-500",
        name: "Error500",
        component: Error500,
        meta: {
            title: "Error500 | Cuba - Premium Admin Template",
        },
    },
    {
        path: "/error-503",
        name: "Error503",
        component: Error503,
        meta: {
            title: "Error503 | Cuba - Premium Admin Template",
        },
    },
    {
        path: "/maintenance",
        name: "Maintenance",
        component: Maintenance,
        meta: {
            title: "Maintenance | Cuba - Premium Admin Template",
        },
    },
    {
        path: "/comingsoon/comingsoon-simple",
        name: "ComingsoonSimple",
        component: ComingsoonSimple,
        meta: {
            title: "ComingsoonSimple | Cuba - Premium Admin Template",
        },
    },
    {
        path: "/comingsoon/comingsoon-image",
        name: "ComingsoonImage",
        component: ComingsoonImage,
        meta: {
            title: "ComingsoonImage | Cuba - Premium Admin Template",
        },
    },
    {
        path: "/comingsoon/comingsoon-video",
        name: "ComingsoonVideo",
        component: ComingsoonVideo,
        meta: {
            title: "ComingsoonVideo | Cuba - Premium Admin Template",
        },
    },
    {
        path: "/authentication/login/one",
        name: "LoginOne",
        component: LoginOne,
        meta: {
            title: "LoginOne | Cuba - Premium Admin Template",
        },
    },
    {
        path: "/authentication/login/two",
        name: "LoginTwo",
        component: LoginTwo,
        meta: {
            title: "LoginTwo | Cuba - Premium Admin Template",
        },
    },
    {
        path: "/authentication/login/validate",
        name: "LoginValidate",
        component: LoginValidate,
        meta: {
            title: "LoginValidate | Cuba - Premium Admin Template",
        },
    },
    {
        path: "/authentication/register/image",
        name: "RegisterImage",
        component: RegisterImage,
        meta: {
            title: "RegisterImage | Cuba - Premium Admin Template",
        },
    },
    {
        path: "/authentication/register/image2",
        name: "RegisterImage2",
        component: RegisterImage2,
        meta: {
            title: "RegisterImage2 | Cuba - Premium Admin Template",
        },
    },
    {
        path: "/authentication/unlockuser",
        name: "Unlock",
        component: Unlock,
        meta: {
            title: "Unlock | Cuba - Premium Admin Template",
        },
    },
    {
        path: "/authentication/forgetpassword",
        name: "ForgetPassword",
        component: ForgetPassword,
        meta: {
            title: "ForgetPassword | Cuba - Premium Admin Template",
        },
    },
    {
        path: "/authentication/resetpassword",
        name: "ResetPassword",
        component: ResetPassword,
        meta: {
            title: "ResetPassword | Cuba - Premium Admin Template",
        },
    },
];

const router = new Router({
    routes,
    base: process.env.BASE_URL,
    mode: "history",
    linkActiveClass: "active",
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});

router.beforeEach((to, from, next) => {
    console.log("started")
        if (to.meta.title) document.title = to.meta.title;
        let CurrentUser = localStorage.getItem('user')==null? false:true;

        const allowedPaths = [
            "/auth/login",
            "/auth/register",
            "/auth/reset-password",
            "/auth/reset",
           
        ];
        const notAllowedWhenLoggedIn =[
            "/auth/login",
            "/auth/register",
            "/auth/reset-password",
            "/auth/reset",
           
        ];

        const JWTuser = localStorage.getItem("user");

        const matchPath = (path) => {
            return allowedPaths.some(allowedPath => {
                if (allowedPath.includes(":")) {
                    const regexPath = allowedPath.replace(/:\w+/g, "\\w+");
                    const regex = new RegExp(`^${regexPath}$`);
                    return regex.test(path);
                }
                return allowedPath === path;
            });
        };

    console.log("started22")
    console.log(to.path)
        if ( matchPath(to.path)){
            console.log("allowed path")
            if(notAllowedWhenLoggedIn.includes(to.path)) {
                if(CurrentUser)
                    return next({
                        path: '/dashboard',
                    });
            }
            return next()
        }else{
           store.dispatch('authentication/verify_token',
                localStorage.getItem("access_token")
                ).then(result=>{
                    console.log("after verify", result)

            })
        }

        if( to.path === "/callback" ||
            CurrentUser ||
            Userauth.isAuthenticatedUser() ||
            JWTuser
        ) {
            return next();


        }

        next({
            path: '/auth/login',
            query: { redirect: to.fullPath }
        });

});






export default router;
