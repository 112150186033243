import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";

const keyList = {
    smsUpdate : 'CONTACT_UPDATE',
    smsDelete : 'CONTACT_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,

    state : {

        contacts:[],
        donations:[],
        contactLoading: false,
        contactError: false,
        contactsubmitSuccess:false,

        ...store
    },
    
    mutations : {
        ...mutations,


        SET_CONTACTS(state, contacts){
            state.contacts = contacts;
        },

        CONTACT_LOADING (state, loading) {
            state.locationLoading = loading;
        },

        CONTACT_ERROR (state, loading) {
            state.locationError = loading;
        },

        CONTACT_SUBMIT_SUCCESS (state, success) {
            state.locationsubmitSuccess = success;
        },

        SET_DONATIONS(state, donations) {
            state.donations = donations;
        },
    },

    actions: {
        //Contact


        async fetchContact ({ commit },payload) {
            commit('CONTACT_LOADING', true);
            commit('CONTACT_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_contact, config);
                const results = result.data.data
                commit('SET_CONTACTS',results);

            } catch(error) {
                commit('CONTACT_ERROR', true);
            }
            commit('CONTACT_LOADING', false);
        },

        


        async deleteContact ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_contact + payload, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch('fetchContact');
            return response
        },


        async toggleContact ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.get(V1API.contact_status_update + payload.slug + '?approved=' + payload.status, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch('fetchContact');
            return response
        },


        async fetchDonationByContact ({ commit },payload) {
            commit('CONTACT_LOADING', true);
            commit('CONTACT_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_donation + payload, config);
                const results = result.data.data
                commit('SET_DONATIONS',results);

            } catch(error) {
                commit('CONTACT_ERROR', true);
            }
            commit('CONTACT_LOADING', false);
        },


    },

    getters: {}
}
