<template>
<div>
    <Breadcrumbs main="Mission Rescue Bangladesh" title="Emergency Contacts" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">

                    <div class="card-header">
                        <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                            <i class="fa fa-light fa-plus"></i>
                            <h7>Add New Emergency Contacts</h7>
                        </button>
                    </div>

                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">

                                <b-input-group class="datatable-btn">
                                    <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>

                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <div v-if="!loading && !error">
                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="emergency_list" :fields="tablefields" :current-page="currentPage" :filter="filter" :per-page="perPage" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                    </template>

                                    <template #cell(location)="data">
                                        <div v-if="data.item.location">
                                            <p>{{data.item.location.name}}</p>
                                        </div>
                                    </template>


                                    <!-- <template #cell(description)="data">
                                        <span>
                                            <div>{{ data.item.description.substring(0, 100) }}</div>
                                        </span>
                                    </template> -->

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteEmergencyContact(data.item.id)" title="Delete"></feather>
                                            <div @click="editLocation(data.item)">
                                                <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit" v-b-modal.modal-prevent-closing-edit></feather>
                                            </div>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
                        </div>
                        <div v-else>
                            <div class="loader-box">
                                <div class="loader-10"></div>
                            </div>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Emergency Contact Create Modal -->
    <b-modal id="modal-prevent-closing" ref="modal" title="Add Emergency Contact" @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group label="Contact Type" label-for="name-input">
                <b-form-input v-model="emergency_contact.contact_type" placeholder="Enter type"></b-form-input>
            </b-form-group>
            <b-form-group label="Contact Name" label-for="name-input">
                <b-form-input v-model="emergency_contact.contact_info" placeholder="Enter person name"></b-form-input>
            </b-form-group>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Contact Details</label>
                        <textarea class="form-control" id="textarea-auto-height" placeholder="Enter contact details..." rows="4" max-rows="6" v-model="emergency_contact.contact_details" ></textarea>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Location</label>
                        <select class="form-control digits" v-model="emergency_contact.location">
                            <option v-for="(type, index) in location_list" :key="index" :value="type.id">{{ type.name }}</option>
                        </select>
                    </div>
                </div>
            </div>

        </form>
    </b-modal>

    <!-- Emergency Contact Edit Modal -->
    <b-modal id="modal-prevent-closing-edit" ref="modal" title="Edit Emergency Contact" @show="resetEditModal" @hidden="resetEditModal" @ok="handleEditOk">
        <form ref="form" @submit.stop.prevent="handleEditSubmit">
            <b-form-group label="Contact Type" label-for="name-input">
                <b-form-input v-model="edit_emergency_contact.contact_type" placeholder="Enter type"></b-form-input>
            </b-form-group>
            <b-form-group label="Contact Name" label-for="name-input">
                <b-form-input v-model="edit_emergency_contact.contact_info" placeholder="Enter person name"></b-form-input>
            </b-form-group>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Contact Details</label>
                        <textarea class="form-control" id="textarea-auto-height" placeholder="Enter contact details..." rows="4" max-rows="6" v-model="edit_emergency_contact.contact_details" ></textarea>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Location</label>
                        <select class="form-control digits" v-model="edit_emergency_contact.location">
                            <option v-for="(type, index) in location_list" :key="index" :value="type.id">{{ type.name }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </form>
    </b-modal>

    <!-- Delete Modal -->
    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this emergency contact?</div>
    </b-modal>
</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

export default {
    data() {
        return {
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'contact_info',
                    label: 'Name',
                    sortable: true
                },
                {
                    key: 'contact_type',
                    label: 'Name',
                    sortable: true
                },
                {
                    key: 'location',
                    label: 'Location',
                    sortable: true
                },

                {
                    key: 'action',
                    label: 'Type',
                    sortable: false
                }
            ],

            statusOptions: [{
                    text: 'Active',
                    value: true
                },
                {
                    text: 'Inactive',
                    value: false
                }
            ],

            emergency_contact: {
                contact_type: '',
                contact_info: null,
                contact_details: null,
                location: null,

            },

            edit_emergency_contact: {
                id: '',
                contact_type: '',
                contact_info: null,
                contact_details: null,
                location: null,

            },

            delete_value: null,
            udate_value: null,

            filter: null,

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15],

            temp_quotation: {}
        };
    },

    computed: {
        ...mapState({
            emergency_list: state => state.emergencyContact.emergency_contact,
            loading: state => state.emergencyContact.emergencyLoading,
            error: state => state.emergencyContact.emergencyError,
            location_list: state => state.location.location,
        }),
        sortOptions() {
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        }
    },

    mounted() {
        this.$store.dispatch("location/fetchLocation")
        this.$store.dispatch("emergencyContact/fetchEmergencyContact", {
            location: 'all'
        }).then(() => {
            this.items = this.emergency_list;
            this.totalRows = this.items.length;

        });

    },
    watch: {

    },

    methods: {
        onFiltered(filteredItems) {

            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        resetModal() {
            this.emergency_contact.contact_type = null
            this.emergency_contact.contact_info = null
            this.emergency_contact.contact_details = null
            this.emergency_contact.location = null

        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },

        handleSubmit() {
            this.$store.dispatch("emergencyContact/addEmergencyContact", {
                 location: 'all',
                 emergency_contact: this.emergency_contact,
            }).then(response => {
                if (response.code == 201) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.location.name = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        },

        editLocation(type) {
            this.edit_emergency_contact.id = type.id? type.id : null
            this.edit_emergency_contact.contact_type = type.contact_type? type.contact_type : null
            this.edit_emergency_contact.contact_info = type.contact_info? type.contact_info : null
            this.edit_emergency_contact.contact_details = type.contact_details? type.contact_details : null
            this.edit_emergency_contact.location = type.location? type.location.id : null

        },

        resetEditModal() {
            this.edit_emergency_contact.id = null
            this.edit_emergency_contact.contact_type = null
            this.edit_emergency_contact.contact_info = null
            this.edit_emergency_contact.contact_details = null
            this.edit_emergency_contact.location = null
        },

        handleEditOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleEditSubmit()
        },

        handleEditSubmit() {
            this.$store.dispatch("emergencyContact/updateEmergencyContact", {
                
                 emergency_contact: this.edit_emergency_contact,
                location: 'all',

            }).then(response => {

                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.edit_location.id = null
                    this.edit_location.name = null
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-edit')
            })
        },
        deleteEmergencyContact(id) {
            this.delete_value = id
        },
        handleDeleteOk() {
            this.$store.dispatch("emergencyContact/deleteEmergencyContact", {delete:this.delete_value,location:'all'}).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },

    }
};
</script>
