
let final_list={
    "data":[
        // {
        //     "path": "/dashboard",
        //     "title": "Dashboard",
        //     "icon": "home",
        //     "type": "link",
        //     "active": false
        // },
        {
            "headTitle1": "Locations",
            "headTitle2": "Manage rescue locations",
            "type": "headtitle"
        },
        {
            "path": "/mission-rescue-bangladesh/location",
            "title": "Locations",
            "icon": "arrow-right",
            "type": "link",
            "active": false
        },
        


        {
            "headTitle1": "Contacts",
            "headTitle2": "Manage Contacts",
            "type": "headtitle"
        },
        {
            "path": "/mission-rescue-bangladesh/contact",
            "title": "Contacts",
            "icon": "arrow-right",
            "type": "link",
            "active": false
        },
        {
            "path": "/mission-rescue-bangladesh/emergency-contact",
            "title": "Emergency Contacts",
            "icon": "arrow-right",
            "type": "link",
            "active": false
        },



        // {
        //     "title": "Press Release",
        //     "type": "sub",
        //     "icon": "disc",
        //     "active": false,
        //     "children": [
        //         {
        //             "path": "/admin/press-release",
        //             "title": "Press Release",
        //             "icon": "disc",
        //             "type": "link"
        //         },
        //         {
        //             "path": "/admin/quotes",
        //             "title": "Quotes",
        //             "icon": "disc",
        //             "type": "link"
        //         },

        //     ]
        // },

    


      
       

      

        
       
       

       
       
       
      
       
     
      
       
    ]
}






export default final_list
