<template>
<div>
    <Breadcrumbs main="Mission Rescue Bangladesh" title="Contacts" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">

                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">

                                <b-input-group class="datatable-btn">
                                    <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>

                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <div v-if="!loading && !error">
                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="contact_list" :fields="tablefields" :current-page="currentPage" :filter="filter" :per-page="perPage" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                    </template>

                                    <template #cell(name)="data">
                                        <div v-if="data.item.name" @click="viewContact(data.item)">
                                            <p class="link-style" v-b-modal.modal-center-details>{{data.item.name}}</p>
                                        </div>
                                    </template>

                                    <template #cell(location)="data">
                                        <div v-if="data.item.location">
                                            {{ data.item.location.name }}
                                        </div>
                                    </template>

                                    <template #cell(status)="data">
                                        <span v-if="data.item.approved === true" style="cursor: pointer;">
                                            <b-badge pill variant="success" v-b-tooltip.hover title="Toggle Status" @click="ToggleVisiblity(data.item)">Yes</b-badge>
                                        </span>
                                        <span v-if="data.item.approved === false" style="cursor: pointer;">
                                            <b-badge pill variant="danger" v-b-tooltip.hover title="Toggle Status" @click="ToggleVisiblity(data.item)">No</b-badge>
                                        </span>
                                    </template>

                                    <template #cell(donation)="data">
                                        <router-link :to="`/mission-rescue-bangladesh/donation/${data.item.slug}`"> <button class="btn btn-primary">Doantion</button>
                                        </router-link>

                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteContact(data.item.id)" title="Delete"></feather>
                                            <feather type="eye" size="22px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center-details @click="viewContact(data.item)" title="View"></feather>
                                        </span>
                                    </template>

                                </b-table>
                            </div>
                        </div>
                        <div v-else>
                            <Loader />
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Location View Modal -->
    <b-modal id="modal-center-details" size="lg" ref="modal" title="Details" hide-footer>
        <form ref="form" @submit.stop.prevent="handleSubmit">

            <div class="row">
                <div class="col-md-12" v-if="edit_contact.personal_image" style="display:flex; justify-content:center;">
                    <div class="form-group" @click="redirectToFullUrl(edit_contact.personal_image)">
                        <img :src="edit_contact.personal_image" alt="" srcset="" style="height: 250px">
                    </div>

                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <b-form-group label="Title" label-for="name-input">
                        <b-form-input v-model="edit_contact.title" placeholder="" disabled></b-form-input>
                    </b-form-group>
                </div>

            </div>
            <div class="row">
                <div class="col-md-6">
                    <b-form-group label="Name" label-for="name-input">
                        <b-form-input v-model="edit_contact.name" placeholder="" disabled></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-md-6">
                    <b-form-group label="Location" label-for="name-input">
                        <b-form-input v-model="edit_contact.location.name" placeholder="" disabled></b-form-input>
                    </b-form-group>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <b-form-group label="NID Number" label-for="name-input">
                        <b-form-input v-model="edit_contact.nid_number" placeholder="" disabled></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-md-6">
                    <b-form-group label="Target" label-for="name-input">
                        <b-form-input v-model="edit_contact.target" placeholder="" disabled></b-form-input>
                    </b-form-group>
                </div>
            </div>

            <div class="row" v-if="edit_contact.phone_numbers">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Phone No</label>
                        <div v-for="(fo, index) in edit_contact.phone_numbers" :key="index" style="margin-bottom: 4px;">
                            <b-form-input v-model="fo.number" type="text" placeholder="" disabled></b-form-input>
                        </div>

                    </div>

                </div>
            </div>

            <div class="row">
                <div class="col-md-12">

                    <div class="form-group">
                        <label>Description</label>
                        <textarea class="form-control" id="textarea-auto-height" placeholder="Description..." rows="10" max-rows="18" v-model="edit_contact.description" disabled></textarea>
                    </div>

                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Banking Details</label>
                    </div>
                </div>
                <div class="col-md-6" v-for="(bank, index) in edit_contact.banking_details" :key="index" style="margin-bottom: 16px;">
                    <b-form-input v-model="bank.account_type" placeholder="" v-b-tooltip.hover title="Account type" readonly style="margin-bottom: 4px;"></b-form-input>
                    <b-form-input v-model="bank.bank_name" placeholder="" readonly v-b-tooltip.hover title="Bank name" style="margin-bottom: 4px;"></b-form-input>
                    <b-form-input v-model="bank.account_name" placeholder="" readonly v-b-tooltip.hover title="Account name" style="margin-bottom: 4px;"></b-form-input>
                    <b-form-input v-model=" bank.account_no" placeholder="" readonly style="margin-bottom: 4px;" v-b-tooltip.hover title="Account number"></b-form-input>
                    <b-form-input v-model=" bank.branch" placeholder="" readonly style="margin-bottom: 4px;" v-b-tooltip.hover title="Bank branch"></b-form-input>
                    <b-form-input v-model="bank.routing_no" placeholder="" readonly v-b-tooltip.hover title="Bank routing no"></b-form-input>

                </div>
            </div>

            <div class="row">

                <div class="col-md-12" v-if="edit_contact.nid_image">
                    <div class="form-group" @click="redirectToFullUrl(edit_contact.nid_image)">
                        <label>NID Link</label>
                        <b-form-input v-model="edit_contact.nid_image" placeholder="" readonly></b-form-input>
                    </div>
                </div>
            </div>

        </form>
    </b-modal>

    <!-- Delete Modal -->
    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this location?</div>
    </b-modal>
</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Loader from '../loader/loader.vue'
import {
    myHost
} from '../../../_helpers/host';
export default {
    components: {
        Loader,
    },
    data() {
        return {
            host: myHost.host,
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'name',
                    label: 'Name',
                    sortable: true
                },
                {
                    key: 'location',
                    label: 'Location',
                    sortable: true
                },
                {
                    key: 'status',
                    label: 'Approved Status',
                    sortable: true
                },
                {
                    key: 'donation',
                    label: 'Doantion',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],

            statusOptions: [{
                    text: 'Active',
                    value: true
                },
                {
                    text: 'Inactive',
                    value: false
                }
            ],

            edit_contact: {
                id: '',
                location: '',
                title: '',
                name: '',
                nid_number: '',
                nid_image: '',
                personal_image: '',
                description: '',
                phone_numbers: '',
                target: '',
                approved: true,
                banking_details: null,

            },

            delete_value: null,
            udate_value: null,

            filter: null,

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15],

            temp_quotation: {}
        };
    },

    // this.form_data.professions = JSON.parse(this.details.form_data.professions)

    computed: {
        ...mapState({
            contact_list: state => state.mrbcontact.contacts,
            loading: state => state.mrbcontact.contactLoading,
            error: state => state.mrbcontact.contactError,
        }),
        sortOptions() {
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        }
    },

    mounted() {

        this.$store.dispatch("mrbcontact/fetchContact", {}).then(() => {
            this.items = this.contact_list;
            this.totalRows = this.items.length;

        });

    },
    watch: {

    },

    methods: {
        redirectToFullUrl(fullUrl) {
            window.open(fullUrl, '_blank');

        },
        viewContact(data) {

            this.edit_contact.id = data.id
            this.edit_contact.location = data.location ? data.location : null
            this.edit_contact.title = data.title ? data.title : null
            this.edit_contact.name = data.name ? data.name : null
            this.edit_contact.nid_number = data.nid_number ? data.nid_number : null
            this.edit_contact.nid_image = data.nid_image ? this.host + data.nid_image : null
            this.edit_contact.personal_image = data.personal_image ? this.host + data.personal_image : null
            this.edit_contact.description = data.description ? data.description : null
            this.edit_contact.phone_numbers = data.phone_numbers ? JSON.parse(data.phone_numbers) : null
            this.edit_contact.target = data.target ? data.target : null
            this.edit_contact.approved = data.approved ? data.approved : null
            this.edit_contact.banking_details = data.banking_details ? JSON.parse(data.banking_details) : null
        },
        onFiltered(filteredItems) {

            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        resetModal() {
            this.location.name = null
        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },

        ToggleVisiblity(data) {

            let newStatus
            if (data.approved) {
                newStatus = false
            } else {
                newStatus = true
            }

            this.$store.dispatch("mrbcontact/toggleContact", {
                slug: data.slug,
                status: newStatus
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.location.name = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },

        editLocation(type) {
            this.edit_location.id = type.id
            this.edit_location.name = type.name

        },

        resetEditModal() {
            this.edit_location.id = null
            this.edit_location.name = null
        },

        deleteContact(id) {
            this.delete_value = id
        },
        handleDeleteOk() {
            this.$store.dispatch("mrbcontact/deleteContact", this.delete_value).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },

    }
};
</script>
