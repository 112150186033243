import Vue from 'vue';
import Vuex from 'vuex';
// import 'es6-promise/auto';
import layout from './modules/layout';
import menu from './modules/menu';
import chat from './modules/chat';
import products from './modules/products';
import email from './modules/email';
import todo from './modules/todo';
import firebase_todo from './modules/firebase_todo';
import jobs from './modules/jobs';
import courses from './modules/courses';
import common from './modules/common';
import { alert } from './modules/alert';
import { authentication } from './modules/authentication';
import { users } from './modules/users';


//Article19
import campaign from './modules/article19/campaign';
import law from './modules/article19/law&policy';
import press from './modules/article19/press';
import news from './modules/article19/news';
import gallery from './modules/article19/gallery'
import contact from './modules/article19/contact'
import team from './modules/article19/team'
import baseDetail from './modules/article19/base_details.js'
import siteSetting from './modules/article19/siteSetting.js';
import qna from './modules/article19/qna.js'
import slider from './modules/article19/slider.js';
import partner from './modules/article19/partner.js';
import report from './modules/article19/annual-report'
import counter from './modules/article19/counter'
import application from './modules/article19/application_form'
import whatWeDo from './modules/article19/what-we-do.js';
import content from './modules/article19/content.js';



//MRB

import location from './modules/mrb/location.js';
import mrbcontact from './modules/mrb/contact.js'
import emergencyContact from './modules/mrb/emergencyContact.js';


Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {},

    mutations: {
        changeLang (state, payload) {
            localStorage.setItem('currentLanguage', payload.id);
            localStorage.setItem('currentLanguageIcon', payload.icon);
            window.location.reload();
        }
    },

    actions: {
        setLang ({ commit }, payload) {
            commit('changeLang', payload);
        }
    },

    modules: {
        alert,
        authentication,
        users,
        layout,
        chat,
        menu,
        products,
        email,
        todo,
        firebase_todo,
        jobs,
        courses,
        common,
        
        

        //Article 19
        campaign,
        law,
        press,
        news,
        gallery,
        contact,
        team,
        baseDetail,
        siteSetting,
        qna,
        slider,
        partner,
        report,
        counter,
        application,
        whatWeDo,
        content,


        //Mission Resuce Bangladesh
        location,
        mrbcontact,
        emergencyContact,
    }
});

